import React, { useState, useEffect } from 'react';
import CarrierTypeForm from "./CarrierTypeForm";

const CarrierTypeTable = ({ token }) => {
  let [carrierTypes, setCarrierTypes] = useState([]);
  const [selectedCarrierType, setSelectedCarrierType] = useState(null);

  useEffect(() => {
    getCarrierTypes();
  }, []);

  let getCarrierTypes = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      };
      let response = await fetch('/api/carrier_type_names/', requestOptions);
      let data = await response.json();
      setCarrierTypes(data);
    } catch (error) {
      console.error('Error fetching carrier types:', error);
    }
  };

  const handleRowClick = (carrierType) => {
    setSelectedCarrierType(carrierType);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div className="medicine-container">
        <div className="table-list" style={{ flex: 1 }}>
          <div className="form-container">
            <div style={{ flex: 1 }}>
              <CarrierTypeForm selectedCarrierType={selectedCarrierType} token={token} />
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Carrier Type Name</th>
                <th>Carrier</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(carrierTypes) && carrierTypes.map((carrierType, index) => (
                <tr key={index} onClick={() => handleRowClick(carrierType)}>
                  <td>{carrierType.carrier_type_name}</td>
                  <td>{carrierType.carrier}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CarrierTypeTable;